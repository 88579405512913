import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import TagManager from 'react-gtm-module';

import { SendMailContextProvider } from 'context/SendMailContext';
import { CategoryTreeContextProvider } from 'context/CategoryTreeContext';
import { backToTopSmooth } from "extra/functions";
import Main from "components/main/Main";

import "./styles/main/main.scss";
import "./styles/main/contrast.scss";

const App = props => {

	useEffect(() => backToTopSmooth(), [ props.location.pathname ])
	
	useEffect(() => {
		TagManager.initialize({ gtmId: 'GTM-N44FFBG' });
	}, [])

	return (
		<CategoryTreeContextProvider>
			<SendMailContextProvider>
				<Main/>
			</SendMailContextProvider>
		</CategoryTreeContextProvider>
	)
}

export default withRouter( App );