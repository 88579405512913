import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom"
import reportWebVitals from './reportWebVitals';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

const renderApp = () => {
	ReactDOM.render(
		<BrowserRouter basename={ window?.eto_config?.basename || "/" }>
			<App/>
		</BrowserRouter>,
		document.getElementById('eto-app')
	);
}

if ( process.env.NODE_ENV !== 'production' ) {
	import('react-axe').then(axe => {
		axe.default(React, ReactDOM, 1000);
		renderApp();
	});
} else {
	renderApp();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
