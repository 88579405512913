import React, { lazy, Suspense } from 'react';
import { Switch, Route } from "react-router-dom";
import Loader from 'components/general/Loader';
import PATHS from "constants/paths";

const Tiles = lazy(() => import( "pages/Tiles" ));
const Category = lazy(() => import( "pages/Category" ));
const Single = lazy(() => import( "pages/Single" ));
const AccessibilityDeclaration = lazy(() => import( "pages/AccessibilityDeclaration" ));
const PrivacyPolicy = lazy(() => import( "pages/PrivacyPolicy" ));
const NotFound = lazy(() => import( "pages/NotFound" ));

const Routes = () => (			
	<Suspense fallback={ <Loader style={{ minHeight: "350px" }}/>}>
		<Switch>
			<Route exact path={ PATHS.MAIN } component={ Tiles } />
			<Route exact path={ PATHS.SUBCATEGORIES()} component={ Tiles } />
			<Route exact path={ PATHS.CATEGORY()} component={ Category } />
			<Route exact path={ PATHS.SINGLE()} component={ Single } />
			<Route exact path={ PATHS.ACCESSIBILITY_DECLARATION } component={ AccessibilityDeclaration }/>
			<Route exact path={ PATHS.PRIVACY_POLICY } component={ PrivacyPolicy } />
			<Route exact path={ PATHS.NOT_FOUND } component={ NotFound } />
			<Route component={ NotFound } />
		</Switch>
	</Suspense>
)


export default Routes;