const getRegulationTitle = title => title ? `<div class="heading"> ${ title } </div>` : "";

export const isFunction = func => toString.call( func ) === "[object Function]";

export const isObject = obj => toString.call( obj ) === "[object Object]";

export const addZeroIfNeed = num => +num > 9 ? num : `0${ num }`;

export const replaceSpacesInUrl = url => url.replace(/\s/g, "%20");

export const isNumber = num => toString.call( num ) === "[object Number]" && !isNaN( num );

export const IsSupportsSmoothScroll = () => 'scrollBehavior' in document.documentElement.style; 

export const validateEmail = email => /^[a-zA-Z0-9-_+.]+@[a-zA-Z-_.0-9]{2,}\.[a-zA-Z]{2,4}$/.test( email );

export const loadScript = options => {
	
	if ( !options.src ) return;
	if ( document.getElementById( options.id )) return;

	const script = document.createElement('script');
	script.id  = options.id;
	script.src = options.src;
	script.type = options.type || "text/javascript";
	script.async = options.async || true;
	script.onload  = options.onload;

	const place = options.place || document.body; 
	place.appendChild( script );
}


export const backToTopSmooth = () => { 
	IsSupportsSmoothScroll()
		? window.scrollTo({ top: 0, behavior: 'smooth' })
		: window.scrollTop = 0;
}


export const getTextFormattedHTML = ( formatted_text, title, start_level ) => {

	const parseLevel = ( arr, level ) => {

		let html = "";

		arr.forEach( item => {

			if ( Array.isArray( item )) {
				html += parseLevel( item, level + 1 );
			} else {

				switch ( level ) {
					case 1:
						html += `<div style="font-size: 1.42em; font-weight: bold; margin: 30px 0"> ${ item } </div>`;
						break;

					case 2:
						html += `<div style="margin: 13px 0"> ${ item } </div>`;
						break;
						
					case 3: 
						const disc = ( /^\d/.test( item ) || /^\w[.)]/.test( item )) ? "" : "● ";
						html += `<div style="padding-left: 15px; margin-bottom: 9px"> ${ disc } ${ item } </div>`;
						break;	
				}
			}
		});

		return html;
	}	


	const parseLinks = html => {

		const links_mathes = html.match(/<a>[^<>]+<\/>[^<>]+<\/a>/gi);
	
		if ( links_mathes ) {

			links_mathes.forEach( match => {
				const [ link, label ] = match.replace(/<\/?a>/g, "").split("</>");
				html = html.replace( match, `<a href="${link}" target="_blank"> ${ label } </a>`);
			})
		}

		return html;
	}

	return getRegulationTitle( title ) + ( parseLinks( parseLevel( formatted_text, start_level || 1 )));
}