import React, { lazy, Suspense, useRef, useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import version from "extra/version";
import { IsSupportsSmoothScroll } from "extra/functions";

const ScrollButtons = lazy(() => import( 'components/general/ScrollButtons' ));

export const MainElementContext = React.createContext();

const Main = props => {

	const is_touch = version === "touch";

	const main_el = useRef( null );
	const [ show_scroll_buttons, setShowScrollButtons ] = useState( false );


	const getMainHeightAndScrollHeight = () => {
		
		if ( !main_el?.current ) return;

		const main = main_el.current;
		const main_height = main.offsetHeight;
		const scroll_height = main.scrollHeight;

		return { main_height, scroll_height };
	}


	const isScrollButtonsVisible = () => { 
		if ( is_touch ) {
			const { main_height, scroll_height } = getMainHeightAndScrollHeight();
			setShowScrollButtons( main_height < scroll_height );
		}
	}


	useEffect(() => {

		if ( is_touch ) {

			isScrollButtonsVisible();

			window.addEventListener("resize", isScrollButtonsVisible );
			return () => window.removeEventListener("resize", isScrollButtonsVisible );
		}

	}, []);


	useEffect(() => { 
		
		if ( is_touch ) {
			scrollTo(0);
			setTimeout(() => isScrollButtonsVisible(), 200 );
		}

	}, [ props.location.pathname ]);


	const scrollTo = top => {
		if ( main_el?.current ) {
			IsSupportsSmoothScroll() 
				? main_el.current.scrollTo({ top, behavior: "smooth" })
				: main_el.current.scrollTop = top;	
		}
	}


	const scrollButtonClick = action => {

		const { main_height, scroll_height } = getMainHeightAndScrollHeight();
		const s_top = main_el.current.scrollTop;
		const gap = 100;

		switch ( action ) {
			case "start": scrollTo( 0 ); break;
			case "end": scrollTo( scroll_height - main_height ); break;

			case "top":
				if ( s_top !== 0 ) {
					scrollTo( s_top - gap <= 0 ? 0 : s_top - gap );
				} 
			break;

			case "bottom":
				if ( s_top !== scroll_height - main_height ) {
					scrollTo( s_top + gap >= scroll_height - main_height ? scroll_height - main_height : s_top + gap );
				}
			break;
		}
	}


	return (
		<MainElementContext.Provider value={{
			isScrollButtonsVisible
		}}>
			<main ref={ main_el } className={ `${ version } has-scrollbar` }>
				{ props.children }

				{( is_touch && show_scroll_buttons ) && 
					<Suspense fallback={<div/>}>
						<ScrollButtons onClick={ scrollButtonClick } />
					</Suspense>
				}
			</main>
		</MainElementContext.Provider>
	)
}


export default withRouter( Main );