import React from 'react';
import version from "extra/version";

import FooterTop from "./FooterTop";
import FooterBottom from './FooterBottom';
import FooterWebTop from "./FooterWebTop";
import "./footer.scss";

const Footer = () => (
	<footer className="footer">
		{ version === "touch" && <FooterTop /> }
		{ version === "web" && <FooterWebTop /> }
		<FooterBottom />
	</footer>
)

export default Footer;