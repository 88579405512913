import React from 'react';
import { Link } from "react-router-dom";

import PATHS from "constants/paths";
import footer_bg from "svg/footer.svg";
import { SlashIcon, MailIcon, PhoneIcon } from "svg/icons";

const links = [
	{ path: PATHS.ACCESSIBILITY_DECLARATION, label: "Deklaracja dostępności" },
	{ path: PATHS.PRIVACY_POLICY, label: "Polityka prywatności" },
	{ svg: <MailIcon />, label: "umgdynia@gdynia.pl", href: "mailto:umgdynia@gdynia.pl" },
	{ svg: <PhoneIcon /> , label: "58 626 26 26", href: "tel:586262626" }
];

const FooterWebTop = () => (
	<div className="footer__top_web" style={{ backgroundImage: `url(${ footer_bg })` }}>

		<div>
			<div className="container df-ac">

				<h2> <SlashIcon/> Elektroniczna Tablica Ogłoszeń </h2>

				<div className="footer__top_web-contacts">
					{ links.map( link => (
						link.path 
							? <Link key={ link.path } to={ link.path } style={{ textDecoration: "underline" }} className="df-ac"> { link.label } </Link>
							: <a key={ link.href } href={ link.href } className="df-ac">
								{ link.svg }
								<span> { link.label } </span> 
							</a>
					))}
				</div>

			</div>
		</div>
	</div>
)

export default FooterWebTop;