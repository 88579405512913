const contrast_key = "contrast_version";

export const isContrastTurnOn = () => localStorage.getItem( contrast_key ) === "on";


export const turnOnContrast = () => {
	localStorage.setItem( contrast_key, "on");
	document.body.classList.add("contrast");
}


export const turnOffContrast = () => {
	localStorage.setItem( contrast_key, "off");
	document.body.classList.remove("contrast");
}


export const toggleContrast = () => {
	!isContrastTurnOn() 
		? turnOnContrast()
		: turnOffContrast();
}


export const changeFontSize = action => {

	if ( ![ "less", "more", "normal" ].includes( action )) return;

	const body = document.body;
	let fontSize = parseInt( window.getComputedStyle( body ).fontSize.replace( "px", "" ));
	
	if ( action === 'less' && fontSize > 10 ) fontSize -= 1;
	if ( action === 'more' && fontSize < 18 ) fontSize += 1;
	if ( action === "normal") fontSize = 14;

	fontSize += "px";
	body.style.fontSize = fontSize;
};