import React from 'react';
import './loader.scss';

const Loader = ({ style, extra_classes }) => (
	<div className={`loader ${ extra_classes || ""}`} style={ style }>
		<div className="cssload-whirlpool"></div>
	</div>
)

export default Loader;
