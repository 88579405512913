import React from 'react';

import { ReactComponent as GCK } from "svg/GCK.svg";
import { ReactComponent as GCKText } from "svg/GCK-text.svg";

export default () => (
	<div>
		<GCK />
		<GCKText />
	</div>
)