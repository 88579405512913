import { 
	AuctionIcon, BuildingsIcon, HeadhuntingIcon, 
	NoticeboardIcon, ConstructionIcon, TargetIcon, ParkIcon,
	BookIcon, ArchitectureIcon, PaperIcon, ApartmentIcon, VotingIcon,
	OrdersIcon
} from "../svg/tiles";

const icon_ids = {
	1 : <AuctionIcon/>, 
	2 : <BuildingsIcon/>,
	3 : <HeadhuntingIcon/>,
	4 : <NoticeboardIcon/>,
	5 : <ConstructionIcon/>,
	6 : <TargetIcon/>,
	7 : <BookIcon/>,
	8 : <ArchitectureIcon/>,
	9 : <PaperIcon/>,
	10 : <ApartmentIcon />,
	11 : <ParkIcon />,
	12 : <VotingIcon/>,
	13 : <OrdersIcon/>
}

export default id => icon_ids[ id ] || null;