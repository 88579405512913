import React, { lazy, useEffect, Suspense } from 'react';
import MainElement from "context/MainElementContext";
import version from "extra/version";
import Routes from "routing/routes";

import Loader from "components/general/Loader";
import Footer from "components/main/Footer";
import { loadScript } from 'extra/functions';

const HeaderTouch = lazy(() => import( "components/main/HeaderTouch" ));
const HeaderWeb = lazy(() => import( "components/main/HeaderWeb" ));
const ScreenSaver = lazy(() => import( "components/main/ScreenSaver" ));
const HelpCenter = lazy(() => import( "extra/help-center" ));

export default function Main() {


	useEffect(() => {
		version === "web" && loadScript({
			src: "https://static.inteliwise.com/embed/9eea502502cb01108f611eb965b6d3449603d958a95c340229125a4d0d8e7dba/3148/space.js"
		})
	}, [])

	return (
		<Suspense fallback={ <Loader/> }>
			{ version === "touch" && 
				<> 
					<HeaderTouch/> 
					<ScreenSaver/>
				</>
			}

			{ version === "web" && 
				<>
					<HeaderWeb/>
					<HelpCenter /> 
				</>
			}

			<MainElement>
				<Routes />
			</MainElement>

			<Footer />
		</Suspense>
	)
}

