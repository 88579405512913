import React from 'react';
import FooterNav from './FooterNav';

const FooterTop = () => (
	<div className="footer__top">
		<div className="container">
			
			<FooterNav />

		</div>
	</div>
)

export default FooterTop;