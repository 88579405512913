import React from 'react';
import GCKLogo from 'components/general/GCKLogo';

const FooterBottom = () => (
	<div className="footer__bottom df-centered">
		<span> powered by </span> 
		<GCKLogo />
	</div>
)

export default FooterBottom;