import axios from "axios";
import { isFunction } from "./functions";

const default_params = window?.eto_config?.default_params || {};
export const API = axios.create({ baseURL: window.eto_config.API_URL });

const CancelToken = axios.CancelToken;

API.createCancelTokenSource = () => CancelToken.source();

API.cancelQuery = cancelFunc => {
	if ( isFunction( cancelFunc )) cancelFunc();
}

API.getCategoriesTree = () => {
	return API.get(`contents/eto_categories`, { params: default_params });
}

API.getPostsByCategory = ( params, cancel_token ) => {
	return API.get(`contents/posts/category/${ params.id }`, { 
		params: { ...params, ...default_params, basic: 1 },
		cancelToken: cancel_token
	});
}

API.getPostById = ( id, cancel_token ) => {
	return API.get(`contents/posts/${ id }`, { 
		params: default_params, 
		cancelToken: cancel_token
	})
}

API.sendEtoLink = ( email, content_id ) => {
	return API.post(`contents/send_eto_link`, { email, content_id });
}
