import React from 'react';
import { withRouter } from "react-router-dom";
import PATHS from "constants/paths";

import { HomeIcon, ArrowLeftIcon, ArrowRightIcon } from "svg/icons";
import { isFunction } from 'extra/functions';


const FooterNav = props => {

	const buttons = [
		{ 
			label: "Strona główna", 
			svg: <HomeIcon/>,
			onClick: () => props.history.push( PATHS.MAIN ) 
		},
		{ 
			label: "Wstecz", 
			svg: <ArrowLeftIcon/>,
			onClick: () => props.history.goBack() 
		},
		{ 
			label: "Dalej", 
			svg: <ArrowRightIcon/>,
			onClick: () => props.history.goForward() 
		}
	];

	return (
		<div className="footer__nav df-ac">
			{ buttons.map(({ label, svg, onClick }) => (
				<a 
					key={ label }
					href="#"
					title={ label }
					onClick={ e => {
						e.preventDefault();
						if ( isFunction( onClick )) onClick();
					}}
				> 
					<span>
						{ svg }
					</span>

					{ label }
				</a>
			)) }

		</div>
	)
}

export default withRouter( FooterNav );