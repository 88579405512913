import React, { lazy, Suspense, useState } from 'react';
import { API } from "extra/api";
import { validateEmail } from "extra/functions";
import Loader from 'components/general/Loader';

const GetPostOnEmailForm = lazy(() => import( "components/form/GetPostOnEmailForm" ));

export const SendMailContext = React.createContext();

export const SendMailContextProvider = props => {

	const [ loading, setLoading ] = useState( false );
	const [ form_visible, setFormVisible ] = useState( false );
	const [ post_id, setPostId ] = useState( null );
	const [ message, setMessage ] = useState( null );
	const [ sent, setSent ] = useState( false );

	const sendQuery = form => {
		
		if ( sent ) return;
		setMessage( null );

		const email = form.email.value;
		
		if ( !email || !validateEmail( email )) {
			setMessage({ type: "error", msg: "Niewłaściwy format adresu e-mail" });
			return;
		}

		if ( email && post_id ) {

			setLoading( true );

			API.sendEtoLink( email, post_id )
			.then( res => {
				setLoading( false );

				setMessage({ type: "success", msg: "Wiadomość została wysłana pomyślnie" });
				setSent( true );
				setTimeout(() => setFormVisible( false ), 2000 );
			})
			.catch( err => {
				setLoading( false );

				const status = err?.response?.status || "";
				setMessage({ type: "error", msg: `Błąd serwera ${ status }` });
			})
		}
	}

	return (
		<SendMailContext.Provider value={{
			setPostId,
			showForm: () => { 
				setMessage( null );
				setSent( false );
				setFormVisible( true );
			},
			closeForm: () => setFormVisible( false )
		}}>
			{ form_visible && 
				<Suspense fallback={ <div className="popup-back"> <Loader extra_classes="white"/> </div> }>
					<GetPostOnEmailForm
						loading={ loading }
						message={ message } 
						onSubmit={ sendQuery } 
						removeMessage={ () => { if ( message ) setMessage( null ) }}
						closeHandler={ () => setFormVisible( false )} 
					/> 
				</Suspense>
			}
			
			{ props.children }
		</SendMailContext.Provider>
	)
}