import React, { useState, useEffect } from 'react';
import { API } from "extra/api";
import { isObject } from "extra/functions";
import { isContrastTurnOn, turnOnContrast } from "extra/theme";
import getCategoryIconById from "extra/categories-icon";
import Loader from 'components/general/Loader';

let CATEGORIES_TREE = {};

export const CategoryTreeContext = React.createContext();

export const CategoryTreeContextProvider = props => {

	const [ loading, setLoading ] = useState( true );

	useEffect(() => {

		if ( isContrastTurnOn()) turnOnContrast();
		
		API.getCategoriesTree()
		.then( res => {

			const categories = res.data?.eto_categories?.categories; 
		
			if ( categories ) {
				CATEGORIES_TREE = prepareCategoriesTree( categories );
				setLoading( false );
			}
		})
		.catch( err => console.log( err ));

	}, []);


	const prepareCategoriesTree = tree => {
		
		const putProperParentId = ( categories, parent_id ) => {
			for ( const k in categories ) {
				const category = categories[k];
				category.parent_id = parent_id;

				if ( isObject( category.subcategories )) {
					putProperParentId( category.subcategories, k );
				}
			} 
		}

		putProperParentId( tree, null );
		return tree;
	}


	const getCategoryById = searched_id => {
			
		let searched_category = {}; 

		const checkCategoriesTreeLevel = tree_level => {
			for ( const k in tree_level ) {
				const category = tree_level[ k ];

				if ( +category.id === +searched_id ) {
					searched_category = category
					return; 
				} else {
					if ( isObject( category.subcategories ))
						checkCategoriesTreeLevel( category.subcategories )
				}
			}
		}

		checkCategoriesTreeLevel( CATEGORIES_TREE || {} );
		return searched_category;
	}


	const getTilesItems = items => {

		const getPropsForTile = item => ({
			id: item.id,
			svg: item.icon ? getCategoryIconById( item.icon ) : "",
			title: item.name,
			subtitle: isObject( item.subcategories )
				? Object.keys( item.subcategories ).length + " Podkategorii" 
				: null,
			has_subcategories: !!isObject( item.subcategories )
		})

		return items && !!items.length
		?	items.map( item => getPropsForTile( item ))
			: []
	}


	const getCategoriesLevel = category_id => {
		
		if ( CATEGORIES_TREE ) {

			if ( !category_id ) return { tiles: getTilesItems( Object.values( CATEGORIES_TREE ))}
			else {
				const category = getCategoryById( category_id ); 
				return {
					prev_level: {
						id: category.parent_id || null,
						name: category.name
					},
					tiles: isObject( category.subcategories )
						? getTilesItems( Object.values( category.subcategories ))
						: []
				};
			}
		}
	}


	if ( loading ) return <Loader/>

	return (
		<CategoryTreeContext.Provider value={{
			getCategoryById,
			getCategoriesLevel
		}}>
			{ props.children }
		</CategoryTreeContext.Provider>
	)
}

